<template>
  <div class="submit">
    <div class="step">
      <img src="@/assets/images/one.jpg" alt="" />
      <ul>
        <p class="left-arrow" />
        <li>
          <p class="title">资料审核</p>
          <p>填写营业执照、身份证、结算账户及营业信息，并完成账户验证</p>
        </li>
        <li>
          <p><i class="el-icon-success green" />已完成</p>
          <!-- <p class="blue">查看资料</p> -->
          <router-link :to="{ name: 'MerchantDetail', query: { merchant_no: $route.query.merchant_no } }"
            >查看资料</router-link
          >
        </li>
      </ul>
    </div>
    <div class="step">
      <img src="@/assets/images/two.jpg" alt="" />
      <ul>
        <p class="left-arrow" />
        <li>
          <p class="title">提交合同</p>
          <!-- <p>上传合同</p> -->
          <el-form :model="form" :rules="rules" label-position="left" ref="form">
            <el-form-item label="上传合同" prop="contract_files">
              <File :tip="[]" v-model="form.contract_files" />
            </el-form-item>
            <el-form-item label="服务期限" prop="date">
              <el-date-picker
                placeholder="请选择服务期限"
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="scheduleContractPost('form')">提交合同</el-button>
            </el-form-item>
          </el-form>
        </li>
        <li>
          <p><i class="el-icon-success blue" />待签约</p>
        </li>
      </ul>
    </div>
    <router-link to="/merchant/list">
      <el-button>返回商户列表</el-button>
    </router-link>
  </div>
</template>

<script>
import File from '@/components/file'
import { scheduleMerchant, scheduleContractPost } from '@/api/api'

export default {
  name: 'Contract',
  components: { File },
  data() {
    return {
      form: {
        date: [],
        contract_files: []
      },
      rules: {
        // contract_files: [{ required: true, message: '请上传合同', trigger: ['change', 'blur'] }],
        date: [{ required: true, message: '请选择服务期限', trigger: ['change', 'blur'] }]
      }
    }
  },
  mounted() {
    const { merchant_no, status } = this.$route.query
    if (status) this.init(merchant_no)
  },
  methods: {
    // 回填数据
    init(merchant_no) {
      scheduleMerchant({ merchant_no }).then((res) => {
        if (res.code === 200) {
          const [date, contract_files] = [
            [res.data.merchant_basic.service_begin_date || '', res.data.merchant_basic.service_end_date || ''],
            res.data.file.contract
          ]
          this.form = { date, contract_files }
        }
      })
    },
    scheduleContractPost(formName) {
      // contract_files,service_begin_date,service_end_date,merchant_no
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { contract_files, date } = this.form
          const params = {
            merchant_no: this.$route.query.merchant_no,
            service_begin_date: date[0],
            service_end_date: date[1]
          }
          contract_files.forEach((item) => delete item.file_url)
          params.contract_files = JSON.stringify(contract_files)
          scheduleContractPost(params).then((res) => {
            if (res.code === 200) {
              this.$router.push('/merchant/list')
            } else {
              this.$message(res.error_message ? res.error_message : '上传错误')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.submit {
  // 资料审核，提交合同
  .step {
    display: flex;
    margin: 40px 0 20px;
    & > img {
      width: 42px;
      height: 42px;
      margin: 30px 30px;
    }
    & > ul {
      flex: 1;
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-right: 30px;
      padding: 20px 30px;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      position: relative;
      // 左箭头
      & > .left-arrow {
        position: absolute;
        top: 44px;
        left: -9px;
        width: 16px;
        height: 16px;
        border-left: 1px solid #dcdfe6;
        border-top: 1px solid #dcdfe6;
        transform: rotate(-44deg);
        background: #fff;
      }
      & > li:nth-of-type(1) {
        flex: 1;
        .el-form {
          margin-top: 30px;
        }
      }
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  & > a {
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>

